import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface SessionState {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

export function createInitialState(): SessionState {
  return {
    uid: '',
    email: '',
    displayName: '',
    photoURL: '',
    emailVerified: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<SessionState> {

  constructor() {
    super(createInitialState());
  }

}
