import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { akitaConfig } from '@datorama/akita';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

// Firebase services + environment module
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS }  from '@angular/fire/compat/remote-config';
import { environment } from '../environments/environment';

import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BuyerPageModule } from './pages/user/sign-up/modals/buyer/buyer.module';
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';
//import { NgxMasonryModule } from 'ngx-masonry';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    //NgxMasonryModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireRemoteConfigModule,
    HttpClientModule,
    NgxDatatableModule,
    //NgxDatatableModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    BrowserAnimationsModule,
    BuyerPageModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, {
    provide: NG_ENTITY_SERVICE_CONFIG,
    useValue: {
      baseUrl: 'https://jsonplaceholder.typicode.com'
    }
  }, FormGroupDirective,
  { provide: DEFAULTS, useValue: { is_property_listing_visible: true, is_searchbar_visibile: true, is_header_buy_active: true, report_price: 499.00 } },
  {
    provide: SETTINGS,
    useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 600000, fetchTimeoutMillis: 600000 } : {}
  }],
  bootstrap: [AppComponent],
  //schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
