// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'apples-with-apples-e075b',
    appId: '1:240373121801:web:2816a82afe547796acc9d8',
    databaseURL: 'https://apples-with-apples-e075b-default-rtdb.firebaseio.com',
    storageBucket: 'apples-with-apples-e075b.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCOQhp0mSOhyPz4xjWulJLLNzEGB1UvEy8',
    authDomain: 'apples-with-apples-e075b.firebaseapp.com',
    messagingSenderId: '240373121801',
    measurementId: 'G-LERC4CFGE5',
  },
  production: false,

  //Must be updated
  payFast: {
    payfast_url: "https://sandbox.payfast.co.za/eng/process",
    merchant_id: '10032926',
    merchant_key: 'e5gsajkwjdqqc',
    return_url: 'https://appleswithapples.co.za/agent-dashboard/payment-confirmed',
    cancel_url: 'https://appleswithapples.co.za/agent-dashboard/valuations',
    notify_url: 'https://updatevaluation-j7ojg6m7vq-uc.a.run.app'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
