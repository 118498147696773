import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/user/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'listings',
    loadChildren: () => import('./pages/agent/listings/listings.module').then( m => m.ListingsPageModule)
  },
  {
    path: 'inspection-details',
    loadChildren: () => import('./pages/property/inspection-details/inspection-details.module').then( m => m.InspectionDetailsPageModule)
  },
  {
    path: 'agent-description',
    loadChildren: () => import('./pages/property/agent-description/agent-description.module').then( m => m.AgentDescriptionPageModule)
  },
  {
    path: 'valuations',
    loadChildren: () => import('./pages/valuer/valuations/valuations.module').then( m => m.ValuationsPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/agent/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/valuer/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/principal-valuer/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'requests',
    loadChildren: () => import('./pages/principal-valuer/requests/requests.module').then( m => m.RequestsPageModule)
  },
  {
    path: 'valuations',
    loadChildren: () => import('./pages/principal-valuer/valuations/valuations.module').then( m => m.ValuationsPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
