import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared-service.service';
import { UserState } from '.';
import { SessionState } from '../session';
import { UserStore } from './user.store';
import { SessionStore } from '../session/session.store';


@Injectable({ providedIn: 'root' })
export class UserService {



  constructor(
    private userStore: UserStore,
    private db: AngularFirestore,
    private sharedService: SharedService,
    private SessionStore: SessionStore
    ) {
  }

  //CRUD opperations for users firestore collection TODO: UD

  createUser(data, uid: string) {
    //creating a user record with the same id as the auth table
    this.db.collection('users').doc(uid).set( {data} ).then(() => {
    });
  }


  async readUser(id: string) {
    this.sharedService.presentLoading('Fetching user details');
    const doc: any = await (await this.db.collection('users').doc(id).ref.get()).data(); //getting the user data
    console.log(doc);
    this.userStore.update(doc); //updating the user store with user data
    
    const userData: SessionState = {
      uid: doc.uid,
      email: doc.email,
      displayName: doc.displayName,
      photoURL: doc.photoURL,
      emailVerified: doc.emailVerified,
    };

    console.log(userData);

    //this.SessionStore.update(userData);

    this.sharedService.dismissLoading();
    //this.userStore.update(this.db.collection('user').doc(id).get())
  }



}
