import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CustomValidationService } from 'src/app/services/custom-validation-service.service';
import { SharedService } from 'src/app/services/shared-service.service';
import { SessionService } from 'src/app/state/session';
import { UserState } from 'src/app/state/user';

@Component({
  selector: 'app-buyer',
  templateUrl: './buyer.page.html',
  styleUrls: ['./buyer.page.scss'],
})
export class BuyerPage implements OnInit {

  credentials: FormGroup;

  validationMessages = {
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'email', message: 'Enter a valid email.' }
    ],
    password: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be at least 6 characters long.' }
    ]
  };

  constructor(
    private modalCtr: ModalController,
    //private afAuth: AngularFireAuth,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private customValidators: CustomValidationService,
    public sessionService: SessionService
  ) { }

  ngOnInit() {
    this.credentials = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      surname: ['', [Validators.required,  Validators.minLength(2), Validators.maxLength(20)]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, {
      validator: this.customValidators.passwordMatchValidator(
        'password',
        'confirmPassword'
        )
    });
  }

  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalCtr.dismiss(closeModal);
  }

  signUp() {
    const userData = {
      name: this.credentials.get('name').value,
      surname: this.credentials.get('surname').value,
      mobile: this.credentials.get('mobile').value,
      license: '',
      company: '',
      role: 'buyer'
    };
    //invoking firebase auth signup function
    this.sessionService.SignUp(this.credentials.get('email').value, this.credentials.get('password').value, userData);
    this.close();
  }



}
