import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SessionStore } from './session.store';

import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared-service.service';
import { SessionState } from '.';
import { resetStores } from '@datorama/akita';
import { UserService, UserState } from '../user';

@Injectable({ providedIn: 'root' })
export class SessionService {

  constructor(private sessionStore: SessionStore,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private sharedService: SharedService,
    private userService: UserService,
    ) {
  }

  // Sign in with email/password
 async SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['']);
        });
        //this.userService.readUser(result.user.uid);
        this.userService.readUser(result.user.uid);

        this.SetUserData(result.user);
        this.sharedService.presentToast(`${email} has signed in`, 'success');
      })
      .catch((error) => {
        this.sharedService.presentError('Ooops', `${error.message}`);
      });
  }
  // Sign up with email/password
  SignUp(email: string, password: string, userData: any) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        //TODO reroute to a sent email verification page.
        this.ngZone.run(() => {
          this.router.navigate(['']);
        });
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);

        //create the user in firestore collection
        const user = {
          name: userData.name,
          surname: userData.surname,
          mobile: userData.mobile,
          role: userData.role
        };
        this.userService.createUser(user, result.user.uid);
        this.sharedService.presentToast('Signup us was successful', 'success');
      })
      .catch((error) => {
        this.sharedService.presentError('Oops...', `${error.message}`);
      });
  }
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user === null;
  }
  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
      if (res) {
        this.router.navigate(['']);
      }
    });
  }
  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  /* Setting up user data when sign in with username/password,
  sign up with username/password will add sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(
    //   `users/${user.uid}`
    // );
    const userData: SessionState = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };

    //updating the session store with the signed in users details
    this.sessionStore.update(userData);


    // return userRef.set(userData, {
    //   merge: true,
    // });

  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      resetStores();
      this.router.navigate(['']);
    });
  }

}
