import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface UserState {
  data: {
    name: string;
    surname: string;
    mobile: string;
    license: string;
    company: string;
    role: string;
  };

}

export function createInitialState(): UserState {
  return {
    data: {
    name: '',
    surname: '',
    mobile: '',
    license: '',
    company: '',
    role: ''
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends Store<UserState> {

  constructor() {
    super(createInitialState());
  }

}
